import type { Ref } from 'vue'

export function useStorage<T>(key: string) {
  const v = ref(uni.getStorageSync(key)) as Ref<T | null>
  const storage = computed({
    get: () => {
      return v.value
    },
    set: (value) => {
      v.value = value
      uni.setStorageSync(key, value)
    },
  })

  return storage
}
