import dayjs from 'dayjs'
import { BUSINESS_LINE } from '~/constants'
import type { chatSourceTypeDict } from '~/utils/dict'
import { type NormalData, get, post, postX } from '~/utils/request'
import { getAppId } from '~/utils/tools'
import useAuthStore from '~/stores/auth'
import { useChat } from '~/stores/chat'

export interface UserInfo {
  phone?: string
}

export function getCaptcha() {
  return get<{ code: number, img: string, uuid: string }>('/code', {}, {
    header: {
      Authorization: '',
    },
  })
}

export async function getAppIdByZoneCode(zoneCode: string) {
  return get<NormalData<string>>('/user/getMpAppId', { id: zoneCode })
}

export async function login(code: string, zoneCode: string) {
  return post<NormalData<{
    access_token: string
    registerWechatMp: boolean
  } & UserInfo>>('/user/login/wechat', {
    businessLine: BUSINESS_LINE,
    appId: await getAppId(),
    loginSource: 'WECHAT_MP',
    code,
    zoneCode,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function loginExternal(payload: {
  phone: string
  code: string
  captcha: string
  uuid: string
  zoneCode: string
}) {
  return post<NormalData<{
    access_token: string
    zoneCode: string
  } & UserInfo>>('/user/login/captcha', {
    ...payload,
    loginSource: 'H5',
    businessLine: BUSINESS_LINE,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function register(payload: {
  phone: string
  code: string
  token: string
}) {
  return post<NormalData<{
    access_token: string
    zoneCode: string
  } & UserInfo>>('/user/register/wechat', {
    businessLine: BUSINESS_LINE,
    registerSource: 'WECHAT_MP',
    ...payload,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function sendRegisterMsg(payload: { phone: string, token: string }) {
  return post('/user/code/sendWithoutCaptcha', {
    captchaType: 'REGISTER',
    zoneCode: useZone().value.zoneCode,
    ...payload,
  }, {
    header: {
      Authorization: '',
    },
  })
}

export function sendLoginMsg(payload: {
  phone: string
  captcha: string
  uuid: string
}) {
  return post('/user/code/send', {
    ...payload,
    captchaType: 'LOGIN',
    zoneCode: useZone().value.zoneCode,
  }, {
    header: {
      Authorization: '',
    },
  })
}

// 查询隐私协议
export function getAgreement(type: string) {
  return get<NormalData<{ content: string }>>('/mis/contract/queryByType', { type })
}

// 服务器时间
export function getServerTime() {
  return get<NormalData<{ timestamp: number }>>('/info')
}

// 退出登录
export function logout() {
  return postX<NormalData>('/user/app/logout', {})
}

type ReceiveSubject = 'QUESTION' | 'AI' | 'SEAT'

export interface ZoneInfo {
  id: number
  zoneId: string
  zoneName: string
  evaluateSendAuto: 'YES' | 'NO'
  evaluateSendSeat: 'YES' | 'NO'
  evaluateSendUser: 'YES' | 'NO'
  evaluateInviteContent: string
  evaluateThankContent: string
  /** hour  */
  evaluateTimeout: number
  /** second */
  sessionTimeout: number
  routePriorityLast: 'YES' | 'NO'
  routeRule: 'POLL' | 'IDLE'
  receiveAuto: 'YES' | 'NO'
  receiveTimeoutSwitch: 'YES' | 'NO'
  /** second  */
  receiveTimeout: number
  receiveTimeoutSwitchRest: 'YES' | 'NO'
  allowVisitor: 'YES' | 'NO'
  robotAvatar: { key: string | null, url: string }
  robotNickname: string
  robotWelcomeTitle: string
  robotWelcomeContent: string
  defineName: string
  receiveSubjectList: ReceiveSubject[]
}

export function getZoneInfo() {
  return get<NormalData<ZoneInfo>>('/chat/app/zoneConfig/getZoneConfigInfo', { zoneCode: useZone().value.zoneCode }, { header: { Authorization: '' } })
}

export interface ZoneSecret {
  zoneCode?: string
  seatGroupId?: number
  sourceType?: (typeof chatSourceTypeDict)[number]['value']
}

export function getLinkInfo(secret: string) {
  return get<NormalData<ZoneSecret>>(`/chat/app/chat/getSeatLink/${secret}`)
}

export interface Account {
  imAccount: string
  imToken: string
  visitorCode?: string
}

export function genAccount(visitorCode: string) {
  return post<NormalData<Account>>('/chat/app/chat/preLogin', {
    zoneCode: useZone().value.zoneCode,
    visitorCode,
  })
}

export function requestWelcome() {
  const z = useZone()
  return post<NormalData>('/chat/app/chat/sendWelcome', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
  })
}

export function requestQuestionReply(questionId: number) {
  const z = useZone()
  return post<NormalData>('/chat/app/chat/sendQuestionReply', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    questionId,
    seatGroupId: useZone().value.seatGroupId,
    sourceType: useZone().value.sourceType,
  })
}

export function requestHuman() {
  const z = useZone()
  return post<NormalData<ServiceRecord & { beforeCount: number }>>('/chat/app/chat/applySeat', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    sourceType: z.value.sourceType,
  })
}

export function getSeatInfo(seatUserId: string) {
  return get<NormalData<{ seatAvatarUrl: string }>>('/chat/app/chat/getSeatInfo', { zoneCode: useZone().value.zoneCode, seatUserId })
}

type SeatMode = 'NORMAL' | 'NIGHT'

export interface ServiceRecordFull extends ServiceRecord {
  seatGroupId: number
  seatGroupName: string
  sourceType: (typeof chatSourceTypeDict)[number]['value']
  userId: string
  visitorCode?: string
  status: ServiceRecordStatus
  evaluateScore?: number
  evaluateContent?: string
  seatMode: SeatMode
}

export function getServiceInfo(serviceRecordId?: number) {
  const z = useZone()
  const s = useServiceRecord()
  return get<NormalData<ServiceRecordFull>>('/chat/app/chat/getServiceInfo', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
    serviceRecordId: serviceRecordId || s.value.serviceRecordId,
  })
}

export function isThereAlreadyEvaluate() {
  const z = useZone()
  const s = useServiceRecord()
  return get<NormalData<boolean>>('/chat/app/chat/alreadySendEvaluateInvite', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    serviceRecordId: s.value.serviceRecordId,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
  })
}

export function sendEvaluate() {
  const z = useZone()
  const s = useServiceRecord()
  return post<NormalData>('/chat/app/chat/sendEvaluateInvite', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
    serviceRecordId: s.value.serviceRecordId,
  })
}

export function closeService() {
  const z = useZone()
  const s = useServiceRecord()
  return post<NormalData>('/chat/app/chat/close', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
    serviceRecordId: s.value.serviceRecordId,
  })
}

export function makeEvaluate(evaluateScore: number, evaluateContent: string, serviceRecordId?: number) {
  const z = useZone()
  const s = useServiceRecord()
  return post<NormalData>('/chat/app/chat/evaluate', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
    serviceRecordId: serviceRecordId || s.value.serviceRecordId,
    evaluateScore,
    evaluateContent,
  })
}

export function queryWaitingInfo() {
  const z = useZone()
  return get<NormalData<ServiceRecord & { beforeCount: number }>>('/chat/app/chat/getApplySeatInfo', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
  })
}

export function heartbeat() {
  return post('/chat/app/chat/heartbeat', {
    zoneCode: useZone().value.zoneCode,
    visitorCode: useVisitorCode().value,
  })
}

export function heartbeatAuthed() {
  const authStore = useAuthStore()
  if (!authStore.user?.token)
    return

  return post('/chat/app/user/heartbeat', {
    zoneCode: useZone().value.zoneCode,
  })
}

export function restartService() {
  const z = useZone()
  const s = useServiceRecord()
  return post('/chat/app/chat/reStartService', {
    zoneCode: z.value.zoneCode,
    visitorCode: useVisitorCode().value,
    seatGroupId: z.value.seatGroupId,
    seatUserId: s.value.seatUserId,
    serviceRecordId: s.value.serviceRecordId,
  })
}

export interface ServiceRecordForQuery {
  imAccount: string
  imToken: string
  to: string
  serviceTimeList: { startTime: number, endTime: number }[]
}

export async function getAllServiceRecords(isVisitor: boolean) {
  const [, res] = await get<NormalData<any[]>>('/chat/app/chat/listServiceLog', {
    zoneCode: useZone().value.zoneCode,
    ...(isVisitor ? { visitorCode: useVisitorCode().value } : {}),
  })
  return (res?.data || []).map(x => ({
    imAccount: x.userId || x.visitorImAccount,
    imToken: x.userImToken || x.visitorImToken,
    to: `seat${x.seatUserId}`,
    serviceTimeList: (x.serviceTimeList || []).map((y: any) => ({ startTime: dayjs(y.startTime).valueOf(), endTime: dayjs(y.endTime).valueOf() })),
  })) as ServiceRecordForQuery[]
}

export async function loginJm(token: string, card: string) {
  return post<NormalData<{
    access_token: string
    phone: string
    name: string
    nickname: string
    avatar: string
  }>>('/user/login/jm', {
    token,
    card,
    zoneCode: useZone().value.zoneCode,
    businessLine: '2099',
    loginSource: 'H5',
  }, { header: { Authorization: '' } })
}

export function askAI(questionList: string[]) {
  const z = useZone()
  return post('/chat/app/chat/sendAiReply', {
    visitorCode: useChat().isVisitor ? useVisitorCode().value : undefined,
    zoneCode: z.value.zoneCode,
    seatGroupId: z.value.seatGroupId,
    sourceType: z.value.sourceType,
    questionList,
  })
}
