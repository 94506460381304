import nim from '@yxim/nim-web-sdk/dist/SDK/NIM_Web_NIM.js'
import { dropRightWhile, sortBy, uniqBy } from 'lodash'
import type { ServiceRecordForQuery } from '~/api'

const nimCtx = {
  instance: null as any,
}

function useNim({
  account,
  token,
  onConnect,
  onDisconnect,
  onWillReconnect,
  onError,
  onMsg,
  ...otherOptions
}: any = {}): any {
  if (!nimCtx.instance) {
    nimCtx.instance = nim.getInstance({
      debug: false,
      appKey: import.meta.env.VITE_APP_CHAT_APP_KEY,
      account,
      authType: 1,
      db: true,
      token,
      needReconnect: true,
      quickReconnect: true,
      onconnect: onConnect,
      ondisconnect: onDisconnect,
      onwillreconnect: onWillReconnect,
      onerror: onError,
      onmsg: onMsg,
      ...otherOptions,
    })
  }
  return nimCtx.instance
}

function clearNim() {
  if (nimCtx.instance)
    nimCtx.instance.destroy()
  nimCtx.instance = null
}

async function queryRemoteHistoryForRanges(instance: any, to: string, ranges: [number, number][], lastMsgId?: string, lastMsgTime?: number) {
  let rangeIndex = 0
  let result: Message[] = []
  while (rangeIndex < ranges.length) {
    const msgs = await queryRemoteHistoryForRange(instance, to, ranges[rangeIndex], lastMsgId, lastMsgTime)
    result = [...result, ...msgs]
    rangeIndex++
  }
  return result
}

async function queryRemoteHistoryForRange(instance: any, to: string, range: [number, number], lastMsgId?: string, lastMsgTime?: number) {
  let result: Message[] = []
  if (lastMsgTime && lastMsgTime < range[1] && lastMsgTime > range[0])
    range = [range[0], lastMsgTime]
  while (true) {
    const msgs = await queryRemoteHistorySingle(instance, to, range, lastMsgId)
    if (msgs.length) {
      result = [...result, ...msgs]
      lastMsgId = msgs[0].idServer
      range = [range[0], msgs[0].time]
    }
    else {
      break
    }
  }
  return result
}

function queryRemoteHistorySingle(instance: any, to: string, range: [number, number], lastMsgId?: string): Promise<Message[]> {
  return new Promise((resolve) => {
    instance.getHistoryMsgs({
      scene: 'p2p',
      to,
      lastMsgId,
      beginTime: range[0],
      endTime: range[1],
      asc: true,
      done: (error?: Error, obj?: { msgs: Message[] }) => {
        if (error)
          resolve([])
        else
          resolve(obj?.msgs || [])
      },
    })
  })
}

async function queryHistory(range: [number, number], records: ServiceRecordForQuery[], lastMsgId?: string, lastMsgTime?: number): Promise<Message[]> {
  let localHistory = await queryLocalHistoryForRange(range)
  if (lastMsgTime)
    localHistory = dropRightWhile(localHistory, msg => msg.time >= lastMsgTime || msg.idServer === lastMsgId)
  if (records.length === 0)
    return localHistory

  let remoteHistoryList: Message[] = []
  for (const record of records) {
    const { to, serviceTimeList } = record
    const instance = useNim()
    const msgs = await queryRemoteHistoryForRanges(instance, to, serviceTimeList.map(x => [x.startTime, x.endTime]), lastMsgId, lastMsgTime)
    remoteHistoryList = [...remoteHistoryList, ...msgs]
  }

  const merged = uniqBy([...localHistory, ...remoteHistoryList], 'idClient')
  return sortBy(merged, 'time')
}

async function queryLocalHistoryForRange(range: [number, number]): Promise<Message[]> {
  const instance = useNim()
  let begin = range[0]
  const end = range[1]
  let result: Message[] = []
  while (true) {
    const msgs = await queryLocalHistorySingle(instance, [begin, end])
    if (msgs.length) {
      result = [...result, ...msgs]
      begin = msgs[msgs.length - 1].time
    }
    else {
      break
    }
  }
  return result
}

function queryLocalHistorySingle(instance: any, range: [number, number]): Promise<Message[]> {
  return new Promise((resolve) => {
    instance.getLocalMsgs({
      desc: false,
      start: range[0],
      end: range[1],
      done: (error?: Error, obj?: { msgs: Message[] }) => {
        if (error)
          resolve([])
        else
          resolve(obj?.msgs || [])
      },
    })
  })
}

function createLocalCustomMsg(type: CustomMessageType, content: any, serviceRecord?: ServiceRecord) {
  const time = Date.now()
  return {
    flow: 'in',
    isLocal: true,
    time,
    custom: serviceRecord ? JSON.stringify(serviceRecord) : undefined,
    from: '',
    fromNick: '',
    idClient: `Client-${time}`,
    idServer: '',
    isHistoryable: false,
    status: 'success',
    to: '',
    type: 'custom',
    contentDecoded: {
      type,
      content,
    },
  } as Message
}

export { useNim, clearNim, queryHistory, createLocalCustomMsg }
