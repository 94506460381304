import { useLocalStorage } from '@vueuse/core'
import { VISITOR_CODE, VISITOR_CODE_REFRESH_TIME } from '~/constants'

export function useVisitorCode() {
  return useLocalStorage(VISITOR_CODE, '', { flush: 'sync' })
}

export function useVisitorCodeRefreshTime() {
  return useLocalStorage(VISITOR_CODE_REFRESH_TIME, 0, { flush: 'sync' })
}
