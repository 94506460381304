<script setup lang="ts">
import { onLaunch } from '@dcloudio/uni-app'
import { createPath, getAppId, getCurrentPath, getCurrentPathWithSearch, getCurrentUrl, initiateWxAuth, isInWeixinBrowser, setAppId, updateIdb } from './utils/tools'
import { type ZoneSecret, getLinkInfo } from './api'
import { useChat } from './stores/chat'
import { useLastJmToken } from './composables/third'
import useAuthStore from '~/stores/auth'
import { DAY } from '~/constants'

function decode(str: string) {
  return JSON.parse(decodeURIComponent(atob(str)))
}

onLaunch(async (ctx) => {
  const appId = ctx?.query.state as string | undefined
  const secret = ctx?.query.secret as string | undefined
  const code = ctx?.query.code as string | undefined
  // from third system
  const token = ctx?.query.t as string | undefined
  const tokenType = (ctx?.query.tp as string | undefined) || 'hyc'
  const extra = (ctx?.query.ext as string | undefined) || ''
  const z = useZone()
  const authStore = useAuthStore()
  let secretInfo: ZoneSecret | undefined
  const vc = useVisitorCode()
  const vct = useVisitorCodeRefreshTime()

  if (vc.value) {
    if (!vct.value || Date.now() - vct.value > 30 * DAY) {
      vc.value = ''
      vct.value = 0
      await updateIdb([])
    }
  }

  if (secret) {
    const [err, res] = await getLinkInfo(secret)
    if (!err)
      secretInfo = res.data
  }

  if (appId)
    setAppId(appId)
  if (secretInfo?.zoneCode) {
    if (z.value.zoneCode && z.value.zoneCode !== secretInfo?.zoneCode)
      authStore.logout()
    z.value = secretInfo
  }

  if (!z.value.zoneCode && location.pathname !== '/pages/404') {
    location.href = '/pages/404'
    return
  }

  await useChat().initSettings()

  /** third party auth */
  if (token) {
    if (tokenType === 'hyc') {
      try {
        const d = decode(token)
        if (d.token) {
          authStore.setUserRaw(d)
          authStore.isFromThird = true
        }
      }
      catch {}
    }
    else if (tokenType === 'jm') {
      const lastJmToken = useLastJmToken()
      if (!lastJmToken || token !== lastJmToken.value || !authStore.verifyAndGetUser()) {
        const success = await authStore.loginByJm(token, extra)
        if (success)
          lastJmToken.value = token
      }
    }
  }

  /** wechat auth */
  if (isInWeixinBrowser()) {
    if (code) {
      const url = new URL(getCurrentUrl())
      url.searchParams.delete('code')
      history.replaceState(null, '', url.toString())
      await authStore.loginByWechat(code, z.value.zoneCode!)
    }
    else {
      let _appId: string | undefined = appId
      if (!_appId)
        _appId = await getAppId(true)
      if (_appId) {
        initiateWxAuth(getCurrentUrl(), _appId)
        return
      }
      else {
        await h5Path()
      }
    }
  }
  else {
    await h5Path()
  }

  authStore.launched = true
})

async function h5Path() {
  const authStore = useAuthStore()
  if (authStore.verifyAndGetUser())
    return
  const chatStore = useChat()
  if (chatStore.settings?.allowVisitor !== 'YES' && !getCurrentPath().startsWith('/pages/register') && location.pathname !== '/pages/404') {
    router.replace(createPath('/pages/register', {
      redirect: getCurrentPathWithSearch(),
    }))
  }
}
</script>
