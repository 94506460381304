import { defineStore } from 'pinia'

import * as Sentry from '@sentry/vue'
import { useChat } from './chat'
import type { UserInfo } from '~/api'
import * as api from '~/api'
import { USERINFO } from '~/constants'
import { createPath, getAppId, getCurrentPath, getCurrentPathWithSearch, getCurrentUrl, initiateWxAuth, isInWeixinBrowser, showAlert } from '~/utils/tools'

interface User {
  token: string
  info?: UserInfo
  expired: number
}

// 1.8h
const LOGIN_EXPIRED = 1.8 * 3600 * 1000

export default defineStore('auth', () => {
  const user = useStorage<User>(USERINFO)
  const authed = computed(() => !!user.value?.token)
  const launched = shallowRef(false)
  const isFromThird = ref(false)

  verifyAndGetUser()

  function verifyAndGetUser() {
    if (user.value && Date.now() < user.value.expired) {
      return user.value
    }
    else {
      setUser(null)
      return null
    }
  }

  function updateExpire() {
    if (user.value)
      user.value.expired = Date.now() + LOGIN_EXPIRED
  }

  function setUser(data: Partial<Omit<User, 'expired'>> | null) {
    user.value = data == null
      ? null
      : {
          ...user.value,
          ...data,
          ...(data.token ? { expired: Date.now() + LOGIN_EXPIRED } : null),
        } as User
  }

  function setUserRaw(data: User) {
    user.value = data
  }

  async function ensureLogin(redirectUrl?: string, reloadFlag = false) {
    let useReplace = false
    if (redirectUrl == null) {
      redirectUrl = getCurrentUrl()
      useReplace = true
    }
    const isLogin = !!verifyAndGetUser()
    const u = new URL(redirectUrl)
    if (!isLogin) {
      if (isInWeixinBrowser()) {
        const appId = await getAppId()
        if (appId)
          await initiateWxAuth(redirectUrl, appId)
      }
      else {
        const registerUrl = createPath('/pages/register', {
          redirect: u.pathname + u.search,
        })
        if (useReplace) {
          if (reloadFlag)
            location.replace(registerUrl)
          else
            router.replace(registerUrl)
        }
        else {
          if (reloadFlag)
            location.href = registerUrl
          else
            router.push(registerUrl)
        }
      }
      return false
    }
    else {
      return true
    }
  }

  async function loginByWechat(code: string, zoneCode: string) {
    setUser(null)
    const [err, res] = await api.login(code, zoneCode)
    if (err) {
      console.error(err.message)
      if (!import.meta.env.DEV) {
        await showAlert('登录失败')
        location.href = '/pages/index'
      }
    }
    else {
      const chatStore = useChat()
      if (res.data.registerWechatMp || chatStore.settings?.allowVisitor === 'YES') {
        const { access_token, ...others } = res.data
        await loginPost(access_token, others)
      }
      else if (!getCurrentPath().startsWith('/pages/register')) {
        await router.replace(createPath('/pages/register', {
          token: res.data.access_token,
          redirect: getCurrentPathWithSearch(),
        }))
      }
    }
  }

  async function loginByJm(token: string, card: string) {
    setUser(null)
    const [err, res] = await api.loginJm(token, card)
    if (err) {
      console.error(err.message)
    }
    else {
      const { access_token, ...others } = res.data
      await loginPost(access_token, others)
      return true
    }
  }

  async function loginPost(token: string, info: UserInfo) {
    setUser({
      token,
      info,
    })
    Sentry.getCurrentScope()?.setUser({ id: info.phone || 'unknown' })
  }

  function logout() {
    setUser(null)
  }

  return {
    user,
    setUserRaw,
    ensureLogin,
    loginByWechat,
    loginPost,
    logout,
    authed,
    launched,
    verifyAndGetUser,
    updateExpire,
    isFromThird,
    loginByJm,
  }
})
