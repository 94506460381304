import { type App, createSSRApp } from 'vue'

// import type { App } from 'vue'
import * as Pinia from 'pinia'

import * as Sentry from '@sentry/vue'
import AppRoot from './App.vue'

import '@unocss/reset/tailwind.css'
import 'uno.css'
import '~/styles/index.scss'
import { showAlert } from './utils/tools'

function forbidCookieInSafari() {
  try {
    localStorage.getItem('anything')
    return false
  }
  catch {
    return true
  }
}

export function createApp() {
  if (forbidCookieInSafari()) {
    showAlert('请前往设置中启用 Cookie，然后刷新页面').then(() => location.reload())
    return
  }
  const app = createSSRApp(AppRoot)
  app.use(Pinia.createPinia())
  initSentry(app)
  return {
    app,
    Pinia,
  }
}

window.addEventListener('pageshow', (e) => {
  if (e.persisted)
    location.reload()
})

window.addEventListener('unload', () => {
  // do nothing
  // just try to prevent browser put this into bfcache
})

function initSentry(app: App) {
  if (import.meta.env.PROD) {
    Sentry.init({
      app,
      dsn: 'https://2e7baad27293d54d61fa330a2c20ddc6@o4504631382310912.ingest.us.sentry.io/4506828645793792',
      tunnel: 'https://sentry.a1mersnow.top',
    })
  }
}
