/**
 * 原生的 loading 是全局的
 * 这里封装 loading 操作，以期望能处理以下情况（s 代表 showLoading，h 代表 hideLoaing，- 代表极短的间隔）：
 * s h | s h
 * s-h | --
 * s s h h | s h
 * s h-s h | s h
 */
let count = 0
export function showLoading(delay = 600, payload = {}) {
  const immediate = delay === 0
  count++
  if (immediate) {
    show()
  }
  else {
    setTimeout(() => {
      if (count)
        show()
    }, delay)
  }

  function show() {
    uni.showLoading({
      title: '加载中...',
      mask: true,
      ...(typeof payload === 'string'
        ? {
            title: payload,
          }
        : payload),
    })
  }
}

export function hideLoading(delay = 300) {
  count = Math.max(count - 1, 0)
  if (!count) {
    setTimeout(() => {
      if (!count)
        hide()
    }, delay)
  }
  function hide() {
    uni.hideLoading()
  }
}
