import { onMounted, onUnmounted } from 'vue'

type Callback = (...args: any[]) => void

const fnMap = new Map<symbol, Set<Callback>>()

export const keys = {
  TOUCH_MESSAGE_LIST: Symbol('touch message list'),
  SEND_TEXT_TO_ROBOT: Symbol('send text to robot'),
  SEND_FILE_TO_ROBOT: Symbol('send file to robot'),
  CHAT_INTERACTING: Symbol('chat interacting'),
  AI_TO_HUMAN: Symbol('ai to human'),
  SEE_IF_NEED_SCROLL_TO_BOTTOM: Symbol('see if need scroll to bottom'),
}

export function emit(key: symbol, ...args: any[]) {
  fnMap.get(key)?.forEach(fn => fn(...args))
}

export function on(key: symbol, fn: Callback) {
  if (!fnMap.has(key))
    fnMap.set(key, new Set())
  fnMap.get(key)!.add(fn)
}

export function off(key: symbol, fn: Callback) {
  fnMap.get(key)?.delete(fn)
}

export function useBus(key: symbol, fn: Callback) {
  onMounted(() => {
    on(key, fn)
  })
  onUnmounted(() => {
    off(key, fn)
  })
}
